if (0 < document.querySelectorAll('.testimonials__slider').length) {
  var testimonials = new Swiper('.testimonials__slider', {
    // Optional parameters
    direction: 'horizontal',
    slidesPerView: 1,
    // Pagination
    pagination: {
      el: '.testimonials__slider__pagination.swiper-pagination',
      clickable: true
    },
    // Navigation arrows
    navigation: {
      nextEl: '.testimonials__slider__navigation .swiper-button-next',
      prevEl: '.testimonials__slider__navigation .swiper-button-prev'
    }
  });
}